@font-face {
  font-family: 'Almarai-Regular';
  src: url('./app/assets/fonts/Almarai-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Almarai-Bold';
  src: url('./app/assets/fonts/Almarai-Bold.ttf') format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New','Almarai-Regular','Almarai-Bold'
    monospace;
}
